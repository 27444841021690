// var qs = require('qs')
//Page loading operation
$(function () {
  //Set the current state of ajax (whether it can be sent);
  ajaxStatus = true;
});
function AjaxFactory() {
  // this.domain = 'http://localhost:15005/capabilitycore';
  this.domain = process.env.DOMAIN

}
/**
* @description jquery ajax func
*/
AjaxFactory.prototype.ajax = function (option) {
  var type = option.type || 'POST';//Request type
  var dataType = option.dataType || 'json';//Receive data type
  var async = option.async || true;//Asynchronous request
  var alone = option.alone || true;//Independent submission (one valid submission)
  var cache = option.cache || false;//Browser history cache
  var success = option.success || function (data) {
    /*console.log('Successful request');*/
    if (data.status) {//Server successfully processed
      setTimeout(function () {
        if (data.url) {
          location.replace(data.url);
        } else {
          location.reload(true);
        }
      }, 1500);
    } else {//Server processing failed
      if (alone) {//Change ajax submission status
        ajaxStatus = true;
      }
    }
  };
  var error = option.error || function (data) {
    /*console.error('Request failed successfully');*/
    /*data.status;//Error status*/
    console.error(data)
  };
  $.ajax({
    url: option.url,
    data: JSON.stringify(option.data),
    type: type,
    dataType: dataType,
    contentType: 'application/json;charset=UTF-8',
    timeout: 30000,
    async: async,
    success: success,
    error: error,
    cache: cache,
    jsonpCallback: 'jsonp' + (new Date()).valueOf().toString().substr(-4),
  });
}

/**
 * @description get request
 * @param {strin} url request url
 * @param {object string} data  request params
 * @param {function} success request success callback
 * @param {boolean} cache is  cache
 */
AjaxFactory.prototype.get = function (url, data, success, cache, alone) {
  this.ajax(url, data, success, cache, alone, false, 'get', 'json');
}
/**
 * @description post request
 * @param {strin} url request url
 * @param {object string} data  request params
 * @param {function} success request success callback
 * @param {boolean} cache is  cache
 */
AjaxFactory.prototype.post = function (url, data, success) {
  var url = this.domain + url;
  var option = {
    url: url,
    data: data,
    success: success,
    error: function () { },
  }
  this.ajax(option);
}
module.exports = {
  AjaxFactory: AjaxFactory
}

